<template>
    <p><strong>{{ year }}</strong> {{ description }}</p>
</template>

<script>
export default {
    name: 'CvEntry',
    props: {
        description: {
            type: String,
            required: true
        },
        year: {
            type: Number,
            required: true
        }
    }
}
</script>